import React, { ReactElement } from "react"
import { Link as GatsbyLink } from "gatsby"

interface Props {
  to: string
  children: string
  className?: string
}

function Link({ to, children, className, ...otherProps }: Props): ReactElement {
  return (
    <GatsbyLink
      className={`hover:text-blue-800 no-underline ${className}`}
      activeClassName="text-brand"
      to={to}
      {...otherProps}
    >
      {children}
    </GatsbyLink>
  )
}

function NavLink({ to, children }: Props): ReactElement {
  return (
    <Link className="pr-4 text-xs sm:text-sm md:text-base" to={to}>
      {children}
    </Link>
  )
}

function NavSmallLink({ to, children }: Props): ReactElement {
  return (
    <Link
      className="self-end hidden pr-4 text-xxs md:text-xs lg:inline"
      to={to}
      style={{
        lineHeight: 1.625,
      }}
    >
      {children}
    </Link>
  )
}

export { Link, NavLink, NavSmallLink }
